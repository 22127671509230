import { createRouter, createWebHistory } from '@ionic/vue-router';
import { auth } from "@/store/auth.store";
import axios from "axios";

const routes = [
    {
        path:'/',
        component: () => import('@/views/Signin.vue')
    },
    {
        path: `/dashboard`,
        component: () => import('@/views/DashboardPage.vue'),
        props: true
    },
    {
        path: `/planning`,
        name: 'Planning',
        component: () => import('@/views/PlanningPage.vue'),
        props: true
    },
    {
        path: `/logbookcustomer`,
        component: () => import('@/views/LogbookCustomer.vue'),
        props: true
    },
    {
        path: `/logbook`,
        component: () => import('@/views/LogbookPage.vue'),
        props: true
    },
    {
        path: `/ordermaterial`,
        component: () => import('@/views/OrderMaterial.vue'),
        props: true
    },
    {
        path: `/remarks`,
        component: () => import('@/views/RemarkPage.vue'),
        props: true
    },
    {
        path: `/absencerequest`,
        component: () => import('@/views/AbsenceRequestPage.vue'),
        props: true
    },
    {
        path: `/profile`,
        component: () => import('@/views/ProfilePage.vue'),
        props: true
    },
    {
        path: '/scan/:id',
        name: 'Scan',
        component: () => import('@/views/ScanPage.vue'),
        props: true
    },
    {
        path: '/task-scan/:id/start',
        name: 'ScanStart',
        component: () => import('@/views/ScanPage.vue'),
        props: true
    },
    {
        path: '/task-scan/:id/stop',
        name: 'ScanStop',
        component: () => import('@/views/ScanPage.vue'),
        props: true
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const actions = {
    async getToken(){
        let token = await auth.actions.getToken();
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer '+token;
        }
    }
}

function getUrlVars()
{
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

router.beforeEach((to, from, next) => {
    actions.getToken();

    const onlyWhenLoggedOut = to.matched.some(
        record => record.meta.onlyWhenLoggedOut
    );

    auth.actions.checkLogin().then(()=> {
        const loggedIn = auth.state.loggedIn;
        if ((!loggedIn && to.path !== '/')) {
            return next({
                path: "/",
                query: { redirect: to.fullPath }
            });
        }else if (loggedIn && to.path == '/') {
            let queryVars = getUrlVars();
            let queryObj = {};

            if(queryVars.length){
                queryVars.forEach(function(value, index){
                    queryObj[queryVars[index]] = queryVars[queryVars[index]];
                });
            }


            if(queryObj.key !== undefined && auth.action !== undefined){
                if(queryObj.key !== auth.action.getToken()) {
                    auth.actions.signOut();
                }
            }

            if(queryObj.redirect !== undefined){
                return next({path: queryObj.redirect});
            }

            return next({path: "/planning", query: queryObj});
        }

        if (loggedIn && onlyWhenLoggedOut) {
            return next("/tabs");
        }

        next();
    });

});

export default router
